import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useVoltar } from '../../hooks/useVoltar'
import { Flex } from '../../components/Flex'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { usuariosApi } from '../../services/api'

export interface ParkingLot {
    id: string
    investidorId: string
    nomeCampanha: string
    dataCadastro: string
    numeroAcoes: number
    numeroDeAcoesBloqueadas: number
  }


export function ListarParkingLotUsuario(): JSX.Element {
  let { usuarioId } = useParams()

  const [parkingLots, setParkingLots] = useState<ParkingLot[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const voltar = useVoltar(`/usuarios/${usuarioId}`)

  useEffect(() => {
    async function fetchParkingLots() {
      try {
        const response = await usuariosApi.get(`/usuarios/${usuarioId}/parkinglot`)
        setParkingLots(response.data)
      } catch (error) {
        toast.error('Erro ao buscar parking lots')
      } finally {
        setIsLoading(false)
      }
    }

    fetchParkingLots()
  }, [usuarioId])

  async function apagarTodos() {
    try {
      await usuariosApi.delete(`/usuarios/${usuarioId}/parkinglot`)
      toast.success('Todos os parking lots foram apagados com sucesso!')
      setParkingLots([])
    } catch (error) {
      toast.error('Erro ao apagar parking lots')
    }
  }

  const header = () => {
    return (
      <>
        <Flex gap='8px' margin='0 0 4px 0'>
          <Link to={voltar}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
          <Button variant='contained' color='secondary' onClick={apagarTodos}>
            APAGAR TODOS
          </Button>
        </Flex>
      </>
    )
  }

  if (isLoading) {
    return (
      <Menu>
        {header()}
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          <Esqueleto height='56px' />
          <Esqueleto height='56px' />
          <Esqueleto height='36px' width='120px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      <>
        {header()}
        <h1>Parking Lots</h1>
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          {parkingLots.length > 0 ? (
            parkingLots.map((parkingLot) => (
              <div key={parkingLot.id}>
                <p><strong>ID:</strong> {parkingLot.id}</p>
                <p><strong>Investidor ID:</strong> {parkingLot.investidorId}</p>
                <p><strong>Nome Campanha:</strong> {parkingLot.nomeCampanha}</p>
                <p><strong>Data Cadastro:</strong> {new Date(parkingLot.dataCadastro).toLocaleDateString('pt-BR')}</p>
                <p><strong>Número Ações:</strong> {parkingLot.numeroAcoes}</p>
                <p><strong>Número de Ações Bloqueadas:</strong> {parkingLot.numeroDeAcoesBloqueadas}</p>
                <hr />
              </div>
            ))
          ) : (
            <p>Nenhum parking lot encontrado.</p>
          )}
        </PilhaDeItens>
      </>
    </Menu>
  )
}