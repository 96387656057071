import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

// @ts-ignore
const browserHistory = createBrowserHistory({ basename: '' })

export let reactPlugin = new ReactPlugin()

// let applicationInsights = new ApplicationInsights({
//   config: {
//     disableTelemetry: process.env.NODE_ENV === 'development',
//     connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_APP_BACKOFFICE,
//     extensions: [reactPlugin],
//     enableAutoRouteTracking: true,
//     extensionConfig: {
//       [reactPlugin.identifier]: { history: browserHistory },
//     },
//   },
// })

// applicationInsights.loadAppInsights()
