import { usuariosApi } from '../services/api'

type ObterUsuarioResponse = {
  id: string
  nomeCompleto: string
  email: string,
  deletedOn: string| null,
  deletedByUserId: string| null
}

export async function obterUsuarioAsync(id: string): Promise<ObterUsuarioResponse> {
  const response = await usuariosApi.get<ObterUsuarioResponse>(`/usuarios/${id}`)
  return response.data
}
